<template>
  <div class="container">
    <div class="page-header d-flex justify-content-between align-items-center mb-1">
      <strong>ระดับเอเย่นต์</strong>
      <button class="btn btn-sm btn-link" @click="addLevel">เพิ่มระดับเอเย่นต์</button>
    </div>
    <div class="card">
      <table class="table table-levels table-sm table-hover mb-0">
        <thead>
          <tr>
            <th class="pl-3" width="15%">สถานะ</th>
            <th width="40%">ระดับ</th>
            <th width="10%">ลำดับ</th>
            <th width="10%">ส่วนแบ่ง</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="level in Levels">
            <td class="pl-3">
              <AccountLevelStatus :status="level.status" />
            </td>
            <td>{{ level.levelName }}</td>
            <td>{{ level.priority }}</td>
            <td>{{ level.share }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-link" @click="editLevel(level)">แก้ไข</button>
              <button v-if="level.status === 'Active'" class="btn btn-sm btn-link text-danger" @click="cancelLevel(level._id)">ยกเลิกใช้งาน</button>
              <button v-if="level.status === 'Inactive'" class="btn btn-sm btn-link text-success" @click="activeLevel(level._id)">ใช้งาน</button>
            </td>
          </tr>
          <tr v-if="Levels.length == 0">
            <td colspan="5" class="small text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <AccountLevelModal :data="data" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import AccountService from "@/services/accountService"

import {accountLevelInterface} from '@/interfaces/Account'
import AccountLevelModal from './components/AccountLevelModal'
import AccountLevelStatus from './components/AccountLevelStatus'

import Swal from 'sweetalert2'

export default {
  name: 'AccountLevels',
  components: {
    AccountLevelModal,
    AccountLevelStatus
  },
  data() {
    return {
      Levels: [],
      isShowModal: false,
      data: accountLevelInterface
    }
  },
  methods: {
    loadAccountLevels() {
      AccountService.getLevels()
      .then((response)=>{
        console.log(response)
        if(response.success === true) {
          this.Levels = response.data
        }
      })
    },
    addLevel() {
      this.data = JSON.parse(JSON.stringify(accountLevelInterface))
      this.isShowModal = true
    },
    editLevel(level) {
      this.data = {
        ...accountLevelInterface,
        ...level
      }
      this.isShowModal = true
    },
    cancelLevel(_id) {
      Swal.fire({
        title: 'ยืนยันยกเลิกใช้งาน!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          AccountService.cancelLevel(_id)
          .then((response)=>{
            if(response.success) {
              this.loadAccountLevels()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ยกเลิกใช้งานเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            console.log(e)
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ยกเลิกใช้งานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    activeLevel(_id) {
      AccountService.reactiveLevel(_id)
      .then((response)=>{
        if(response.success) {
          this.loadAccountLevels()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เปลี่ยนสถานะใช้งานเสร็จเรียบร้อย',
          })
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'เปลี่ยนสถานะใช้งานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    modalClosed(needReload) {
      this.isShowModal = false
      console.log('needReload', needReload)
      if(needReload) {
        this.loadAccountLevels()
      }
    }
  },
  mounted() {
    this.loadAccountLevels()
  }
}
</script>
<style lang="scss" scoped>
.container {
  table.table-levels {
    thead {
      tr {
        th {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          height: 40px;
        }
        // button {
        //   display: none;
        // }
      }
      // tr:hover {
      //   button {
      //     display: inline-table;
      //   }
      // }
    }
  }
}
</style>
